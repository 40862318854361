import React from "react";
import { makeObservable, action, observable, computed } from "mobx";
import { getCouponsDataServer } from "./server/getCouponsDataServer";
import { purchaseCouponServer } from "./server/purchaseCouponServer";
import { getCouponsTableData } from "./logic/getCouponsTableData";
import { errorCodes } from "../../services/errorCodes";
import { validateIsraeliIdStructure } from "common/validateIsraeliIdStructure/validateIsraeliIdStructure";
import { getSearchStreet } from "./logic/getSearchStreet";
import { activationTypes } from "./logic/coupontypeActivationTypes";
import { getIsDeliveryType } from "../components/addressForm";
import { validateInputs } from "./logic/validateInputs";
import { httpCall } from "../../../../common/httpCall";
import { handlePickupErrorCode } from "./logic/handlePickupErrorCode";
import { getStreetAndHouseSearchResults } from "./logic/getStreetAndHouseSearchResults";
import { calculateDistance } from "./logic/calculateDistance";
import toast from "react-hot-toast";
import { t } from "../../../../common/localization/translate";

const ScreenPurchaseFromSupportStoreContext = React.createContext({});
export const ScreenPurchaseFromSupportProvider = ScreenPurchaseFromSupportStoreContext.Provider;
export const useScreenPurchaseFromSupportStore = () => React.useContext(ScreenPurchaseFromSupportStoreContext);

class ScreenPurchaseFromSupportStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.isFetching = false;
        this.isPurchasing = false;
        this.isFetchingStreets = false;
        this.purchaseCouponErrorCode = "";
        this.userIdentifier = "";
        this.allCoupons = null;
        this.isShowPurchaseModal = false;
        this.isShowDeliveryModal = false;
        this.errorCode = null;
        this.errorMessageStreet = null;
        this.coupon = "";
        this.userName = "";
        this.currentRow = null;
        this.isSuccessPurchaseCoupon = false;
        this.deliveryUserName = "";
        this.deliveryUserPhone = "";
        this.searchStreetValue = "";
        this.userNameError = "";
        this.deliveryUserPhoneError = "";
        this.houseError = "";
        this.streetsList = "";
        this.isOpenedStreetsList = false;
        this.city = "";
        this.isFetchingPickupPoints = false;
        this.isFetchingPickupStreetAndHouse = false;
        this.streetAndHouseSearch = "";
        this.errorMessageStreetAndHouse = "";
        this.isOpenedPickupStreetAndHouse = false;
        this.pickupStreetAndHouseSearchResult = "";
        this.pickupCompany = "";
        this.pickupErrorMessage = "";
        this.pickupPoints = "";
        this.pickupCompany = "";
        this.isOpenedPickupPoints = false;
        this.sessionTokenForSearch = "";
        this.externalUserId = "";
        this.selectedPickupPoint = "";
        this.street = "";
        this.house = "";
        this.color = "";
        this.size = "";
        this.apartment = "";
        this.afterPurchaseData = "";
        this.extraEmail = "";
        this.extraForPurchase = "";
        this.userCoins = "";
        this.initProperties();

        makeObservable(this, {
            isFetching: observable,
            isPurchasing: observable,
            purchaseCouponErrorCode: observable,
            userIdentifier: observable,
            allCoupons: observable,
            isShowPurchaseModal: observable,
            isShowDeliveryModal: observable,
            errorCode: observable,
            coupon: observable,
            currentRow: observable,
            isSuccessPurchaseCoupon: observable,
            couponsTableData: computed,
            setIsSuccessPurchaseCoupon: action.bound,
            setPurchaseCouponErrorCode: action.bound,
            validateDeliveryForm: action.bound,
            setCurrentCoupon: action.bound,
            setCurrentRow: action.bound,
            showPurchaseModal: action.bound,
            purchaseCoupon: action.bound,
            purchaseModalOkPressed: action.bound,
            setIsDeliveryModalVisible: action.bound,
            setAllCoupons: action.bound,
            setUserIdentifier: action.bound,
            setErrorCodes: action.bound,
            setIsFetching: action.bound,
            getScreenData: action.bound,
            initProperties: action.bound,
            submitIdentifier: action.bound,
            sessionTokenForSearch: observable,
            externalUserId: observable,
            userName: observable,
            userCoins: observable,
            getAndSetStreetSearchResults: action.bound,
            pickupStreetAndHouseSearchResult: observable,
            searchAllCollectionPoints: action.bound,
            searchStreetAndHouseCollectionPoints: action.bound,
            isFetchingPickupStreetAndHouse: observable,
            sortByDistance: action.bound,
            streetAndHouseSearch: observable,
            errorMessageStreetAndHouse: observable,
            isOpenedPickupStreetAndHouse: observable,
            isFetchingPickupPoints: observable,
            pickupCompany: observable,
            resetPickupObservables: action.bound,
            pickupErrorMessage: observable,
            isOpenedPickupPoints: observable,
            pickupPoints: observable,
            setSelectedPickupPoint: action.bound,
            selectedPickupPoint: observable,
            deliveryUserName: observable,
            deliveryUserPhone: observable,
            cleanDeliveryInfo: action.bound,
            userNameError: observable,
            deliveryUserPhoneError: observable,
            houseError: observable,
            streetsList: observable,
            isOpenedStreetsList: observable,
            isFetchingStreets: observable,
            errorMessageStreet: observable,
            updateStreet: action.bound,
            city: observable,
            street: observable,
            searchStreetValue: observable,
            house: observable,
            apartment: observable,
            afterPurchaseData: observable,
            extraEmail: observable,
            extraForPurchase: observable,
            setExtraForPurchase: action.bound,
            color: observable,
            size: observable,
            setUserData: action.bound,
        });
    }

    initProperties() {
        this.currentRow = null;
    }

    setUserIdentifier(userIdentifier) {
        this.userIdentifier = userIdentifier;
    }

    setAllCoupons(value) {
        this.allCoupons = value;
    }

    setErrorCodes(errorCode) {
        this.errorCode = errorCode;
    }

    showPurchaseModal(isShowPurchaseModal) {
        this.isShowPurchaseModal = isShowPurchaseModal;
    }

    setCurrentRow(currentRow) {
        const isDifferentRow = this.currentRow != currentRow;
        this.currentRow = isDifferentRow ? currentRow : null;
    }

    setIsSuccessPurchaseCoupon(isSuccess) {
        this.isSuccessPurchaseCoupon = isSuccess;
        if (isSuccess) {
            this.resetPickupObservables();
            this.selectedPickupPoint = null;
            this.streetAndHouseSearch = "";
            this.searchStreetValue = "";
            this.deliveryUserName = "";
            this.deliveryUserPhone = "";
            this.house = "";
            this.apartment = "";
        }
    }

    setIsDeliveryModalVisible(isVisible) {
        this.isShowDeliveryModal = isVisible;
    }

    setPurchaseCouponErrorCode(errorCode) {
        this.purchaseCouponErrorCode = errorCode;
    }

    async purchaseModalOkPressed() {
        const isDeliveryType = getIsDeliveryType(this.coupon.activationType);
        if (isDeliveryType) {
            this.showPurchaseModal(false);
            this.setIsDeliveryModalVisible(true);
        } else {
            this.purchaseCoupon();
        }
    }

    async purchaseCoupon() {
        this.isPurchasing = true;
        this.setIsSuccessPurchaseCoupon(false);
        this.setPurchaseCouponErrorCode(null);
        this.setExtraForPurchase();
        const { couponTypeId } = this.coupon;
        const segmentId = this.rootStore.userInfoStore.currentSegment?.segmentId || null;
        const { isSuccess, errorCode, afterPurchaseData } = await purchaseCouponServer({
            userIdNum: this.userIdentifier,
            segmentId,
            couponTypeId,
            extraForPurchase: this.extraForPurchase,
        });
        this.setIsDeliveryModalVisible(false);
        if (!isSuccess) {
            this.setPurchaseCouponErrorCode(errorCode);
        } else {
            const { coinsTotal } = afterPurchaseData;
            this.userCoins = coinsTotal;
            this.afterPurchaseData = afterPurchaseData;
            this.currentRow = null;
            this.setIsSuccessPurchaseCoupon(true);
        }
        this.showPurchaseModal(false);
        this.isPurchasing = false;
    }

    sendAdditionalMail = async additionalEmail => {
        const segmentId = this.rootStore.userInfoStore.currentSegment?.segmentId || null;
        const { couponTypeId } = this.coupon;
        const { isSuccess, errorCode } = await purchaseCouponServer({
            userIdNum: this.userIdentifier,
            segmentId,
            couponTypeId,
            extraForPurchase: this.extraForPurchase,
            additionalEmail,
            extraAfterPurchase: this.afterPurchaseData.extra,
        });
        if (!isSuccess) {
            this.setPurchaseCouponErrorCode(errorCode);
        } else {
            toast(t("screenPurchaseFromSupport.afterSendEmail.toast"), {
                style: { borderRadius: "10px", background: "#333", color: "#fff", fontFamily: "Arial" },
            });
        }
    };

    setExtraForPurchase() {
        this.extraForPurchase = {
            couponTypeId: this.coupon.couponTypeId,
        };
        if (this.coupon.activationType == activationTypes.DELIVERY || this.coupon.activationType == activationTypes.COLLECTING_POINT) {
            const deliveryInfo = {
                apartment: this.apartment,
                city: this.city,
                // color: this.color,
                // size: this.size,
                fullAddress: `${this.city} ${this.street}, ${this.house} ${this.apartment && "מספר דירה:"} ${this.apartment}`,
                street: this.street,
                houseNumber: this.house,
                phone: this.deliveryUserPhone,
                userName: this.deliveryUserName,
            };
            this.extraForPurchase.deliveryInfo = deliveryInfo;
            this.extraForPurchase.activationType = this.coupon.activationType;
        } else if (this.coupon.activationType == activationTypes.PICKUP) {
            const deliveryInfo = {
                city: this.city,
                house: this.house,
                phone: this.deliveryUserPhone,
                pickupCode: this.selectedPickupPoint.pickupCode,
                pickupName: this.selectedPickupPoint.pickupName,
                pickupType: this.pickupCompany,
                street: this.street,
                userName: this.deliveryUserName,
                // color: this.color,
                // size: this.size,
            };
            this.extraForPurchase.deliveryInfo = deliveryInfo;
            this.extraForPurchase.activationType = this.coupon.activationType;
            this.extraForPurchase.couponTypeId = this.coupon.couponTypeId;
        }
    }

    cleanDeliveryInfo() {
        this.deliveryUserName = "";
        this.deliveryUserPhone = "";
        this.city = "";
        this.street = "";
        this.house = "";
        this.apartment = "";
        this.searchStreetValue = "";
        this.errorMessageStreet = "";
        this.userNameError = "";
        this.deliveryUserPhoneError = "";
        this.houseError = "";
        this.color = "";
        this.size = "";
        this.streetsList = "";
    }

    validateDeliveryForm() {
        return validateInputs(this);
    }

    setCurrentCoupon(coupon) {
        this.coupon = coupon;
    }

    getScreenData = async () => {
        try {
            this.setAllCoupons(null);
            this.setIsFetching(true);
            const segmentId = this.rootStore.userInfoStore.currentSegment?.segmentId || null;
            const response = await getCouponsDataServer(this.userIdentifier, segmentId);
            this.setUserData(response.userView);
            this.setErrorCodes(response.errorCode);
            this.setAllCoupons(response.couponsData);
            this.setIsFetching(false);
        } catch (e) {
            this.setErrorCodes(errorCodes.ERROR_TYPE_USER_NOT_EXISTS);
            this.setIsFetching(false);
        }
    };

    setUserData(userView) {
        this.userName = userView.name;
        this.userCoins = userView.coinsTotal;
        this.externalUserId = userView.externalUserId;
    }

    submitIdentifier() {
        this.initProperties();
        const isValidId = validateIsraeliIdStructure(this.userIdentifier);

        if (!isValidId) {
            this.setErrorCodes(errorCodes.ERROR_TYPE_INVALID_DATA);
            return;
        }
        this.getScreenData();
    }

    setIsFetching(isFetching) {
        this.isFetching = isFetching;
    }

    get couponsTableData() {
        return getCouponsTableData(this);
    }

    getAndSetStreetSearchResults = async searchText => {
        this.isFetchingStreets = true;
        await getSearchStreet(searchText, this);
        this.isFetchingStreets = false;
    };

    searchStreetAndHouseCollectionPoints = async text => {
        if (!text) {
            return;
        }
        this.isFetchingPickupStreetAndHouse = true;
        this.pickupStreetAndHouseSearchResult = await getStreetAndHouseSearchResults({ text, sessionToken: this.sessionTokenForSearch, isIsraeliCities: true });
        this.isFetchingPickupStreetAndHouse = false;
    };

    searchAllCollectionPoints = async () => {
        try {
            this.resetPickupObservables();
            this.isFetchingPickupPoints = true;
            const res = await httpCall("screens/deliveryPickup/getPickupPointsHFD", { couponTypeId: this.coupon.couponTypeId });
            this.isFetchingPickupPoints = false;
            if (res.errorCode) {
                handlePickupErrorCode(this, res.errorCode);
                return;
            }
            this.pickupPoints = res.extra.pickupPoints;
            this.pickupCompany = res.extra.pickupCompany;
        } catch (e) {
            this.isFetchingPickupPoints = false;
        }
    };

    sortByDistance = async place => {
        this.isFetchingPickupPoints = true;
        const segmentId = this.rootStore.userInfoStore.currentSegment?.segmentId || null;
        const coordinates = await httpCall("support/chooseCity/getCoordinates", { place, sessiontoken: this.sessionTokenForSearch, segmentId });

        if (!coordinates || !coordinates.lng || !coordinates.lat) {
            this.isFetchingPickupPoints = false;
            return [];
        }

        const sortedPickupPoints = this.pickupPoints
            .map(point => ({
                ...point,
                distance: calculateDistance(coordinates, point),
            }))
            .sort((a, b) => a.distance - b.distance)
            .slice(0, 50);

        this.isFetchingPickupPoints = false;
        this.pickupPoints = sortedPickupPoints;
    };

    setSelectedPickupPoint = item => {
        this.selectedPickupPoint = item;
        this.city = item.city;
        this.street = item.street;
        this.house = item.house;
    };

    resetPickupObservables = () => {
        if (this.pickupCompany) {
            this.pickupErrorMessage = null;
            this.pickupPoints = null;
            return;
        }
        this.pickupErrorMessage = null;
        this.pickupPoints = null;
        this.pickupCompany = null;
    };

    updateStreet = address => {
        this.errorMessageStreet = null;
        const { street, city } = address;
        this.city = city;
        this.street = street;
    };

    getIsButtonDisabled() {
        if (this.coupon.activationType == activationTypes.DELIVERY || this.coupon.activationType == activationTypes.COLLECTING_POINT) {
            return !this.deliveryUserName || !this.deliveryUserPhone || !this.house;
        } else if (this.coupon.activationType == activationTypes.PICKUP) {
            return !this.deliveryUserName || !this.deliveryUserPhone || !this.selectedPickupPoint;
        }
    }
}

export function createScreenPurchaseFromSupportStore(rootStore) {
    const store = new ScreenPurchaseFromSupportStore(rootStore);
    return store;
}
