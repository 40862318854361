import React from "react";
import { CircularProgress } from "@mui/material";
import { observer } from "mobx-react";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    spinner: {
        marginRight: "5px",
    },
}));

export const PickupPointsInputArrow = observer(() => {
    const classes = useStyles();

    const store = useScreenPurchaseFromSupportStore();
    let leftComponent = null;

    if (!store.streetAndHouseSearch) {
        if (store.isFetchingPickupPoints && store.streetAndHouseSearch) {
            leftComponent = <CircularProgress size={20} className={classes.spinner} />;
        } else {
            leftComponent = null;
        }
        return leftComponent;
    }
    if (store.isOpenedPickupPoints) {
        leftComponent = <ArrowDropUpIcon />;
    } else {
        leftComponent = <ArrowDropDownIcon />;
    }

    return leftComponent;
});
