import find from "lodash/find";

export async function updateStoreByTypeSupport(store, serverRes) {
  const { allowedSegments, isAllowedToSendNotification, isAllowedToHideCoupons, isAllowedToCheckEntitlement, isCanPurchaseCoupons } = serverRes;
  store.isAllowedToSendNotification = isAllowedToSendNotification;
  store.isAllowedToHideCoupons = isAllowedToHideCoupons;
  store.isCanPurchaseCoupons = isCanPurchaseCoupons
  store.isAllowedToCheckEntitlement = isAllowedToCheckEntitlement;
  store.setAllowedSegments(allowedSegments);

  const currentSegmentInArray = store.currentSegmentId ? find(allowedSegments, (item) => item.segmentId == store.currentSegmentId) : null;
  const currentSegmentToSet = currentSegmentInArray || store.currentSegment || allowedSegments[0];
  
  store.setCurrentSegmentId(currentSegmentToSet.segmentId);
  store.setCurrentSegment(currentSegmentToSet);
  store.setDashboardFeatures(currentSegmentToSet.dashboardFeatures);
}
