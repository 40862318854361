const MAXIMUM_ATTEMPTS = 10;

export async function delay(tokenRefreshStatus) {
  for (let i = 0; i < MAXIMUM_ATTEMPTS; i++) {
    await new Promise((resolve) => setTimeout(resolve, 200));

    if (!tokenRefreshStatus.isRefreshing) {
      break;
    }
  }
}
