import axios from "axios";
import { getBaseUrl } from "common/getBaseUrl";
import { logRefreshJwtLogicError } from "./logRefreshJwtLogicError";

export async function sendRetryRequestLog({ originalRequest }) {
  const url = "dashboards/sendRetryRequestLog";
  const baseUrl = getBaseUrl();
  const urlWithBase = `${baseUrl}${url}`;

  try {
    await axios({
      url: urlWithBase,
      method: "POST",
      data: { originalRequestUrl: originalRequest.url },
      withCredentials: true,
    });
  } catch (e) {
    logRefreshJwtLogicError({
      context: "Error in sendRetryRequestLog",
      error: e,
      extra: { originalRequestUrl: originalRequest.url },
    });
  }
}
