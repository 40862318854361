import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { CommonButton } from "common/ui/commonButton";
import { coinsIconWowJunior } from "../idNumForm/userNameAndCoins";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.white,
        fontSize: 18,
        fontWeight: "700",
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
        },
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    rowTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
    },
    coinsIcon: { height: 35, paddingBottom: 2 },
    coins: { fontWeight: "bold" },
    bold: { fontWeight: "bold", paddingLeft: 5 },
    content: { marginTop: 8 },
    buttonPrimary: {
        fontSize: 14,
        marginLeft: 21,
        marginTop: 18,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
        width: "150px",
    },
    buttonSecondary: {
        fontSize: 14,
        marginLeft: 21,
        marginTop: 18,
        backgroundColor: theme.palette.white,
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
        width: "150px",
    },
    dialog: {
        width: 500,
        minHeight: 180,
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "center",
    },
}));

export const PurchaseCouponModal = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();

    const handleClickClose = () => {
        store.showPurchaseModal(false);
    };

    const purchaseSelectedCoupon = () => {
        store.purchaseModalOkPressed();
    };

    const { userName, coupon, isPurchasing, isShowPurchaseModal } = store;
    const { couponName, coins } = coupon;

    return (
        <div>
            <Dialog open={isShowPurchaseModal} onClose={handleClickClose} aria-labelledby="responsive-dialog-title">
                <div className={classes.dialog}>
                    <DialogTitle className={classes.root} id="responsive-dialog-title">
                        {t("screenPurchaseFromSupport.coupons.purchaseModal.title")}
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <DialogContentText className={classes.rowTitle}>
                            {t("screenPurchaseFromSupport.coupons.purchaseModal.coupon")}
                            <DialogContentText className={classes.bold}>{couponName}</DialogContentText>
                        </DialogContentText>
                        <DialogContentText className={classes.row}>
                            {t("screenPurchaseFromSupport.coupons.purchaseModal.price")}
                            <DialogContentText className={classes.row}>
                                <img alt="coins" src={coinsIconWowJunior} className={classes.coinsIcon} />
                                <DialogContentText className={classes.coins}>{coins}</DialogContentText>
                            </DialogContentText>
                        </DialogContentText>
                        <DialogContentText className={classes.row}>
                            {t("screenPurchaseFromSupport.coupons.purchaseModal.userName")}
                            <DialogContentText className={classes.bold}>{userName}</DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.buttonsContainer}>
                        <LoadingButton loading={isPurchasing} loadingIndicator={<CircularProgress color="info" size={20} />} className={classes.buttonPrimary} onClick={purchaseSelectedCoupon}>
                            {t("screenPurchaseFromSupport.coupons.purchaseModal.buttons.yes")}
                        </LoadingButton>
                        <CommonButton className={classes.buttonSecondary} onClick={handleClickClose} color="primary" autoFocus>
                            {t("screenPurchaseFromSupport.coupons.purchaseModal.buttons.no")}
                        </CommonButton>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
});
