import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { AddressForm } from "../addressForm";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { CommonButton } from "../../../../../common/ui/commonButton";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.white,
        fontSize: 18,
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 18,
        },
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    content: { marginTop: 8 },
    dialogDiv: {
        height: 400,
    },
    button: {
        fontSize: 14,
        marginLeft: 21,
        marginTop: 18,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
        width: "150px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
    },
    buttonSecondary: {
        fontSize: 14,
        marginLeft: 21,
        marginTop: 18,
        backgroundColor: theme.palette.white,
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
        width: "150px",
    },
}));

export const DeliveryModal = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const { isShowDeliveryModal, isPurchasing } = store;
    const classes = useStyles();

    const onClose = () => {
        store.cleanDeliveryInfo();
        store.setIsDeliveryModalVisible(false);
    };

    const onOkPress = () => {
        const isValid = store.validateDeliveryForm();
        if (isValid) {
            store.purchaseCoupon();
        }
    };

    const isButtonDisabled = store.getIsButtonDisabled();

    return (
        <div>
            <Dialog open={isShowDeliveryModal} aria-labelledby="responsive-dialog-title">
                <div className={classes.dialogDiv}>
                    <DialogTitle className={classes.root} id="responsive-dialog-title">
                        {t("screenPurchaseFromSupport.coupons.deliveryModal.title")}
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <AddressForm />
                    </DialogContent>
                    <DialogActions className={classes.buttonContainer}>
                        <LoadingButton loading={isPurchasing} loadingIndicator={<CircularProgress color="info" size={20} />} className={classes.button} size="small" type="submit" variant="contained" onClick={onOkPress} disabled={isButtonDisabled}>
                            {t("screenPurchaseFromSupport.coupons.deliveryModal.button")}
                        </LoadingButton>
                        <CommonButton className={classes.buttonSecondary} onClick={onClose} color="primary" autoFocus>
                            {t("screenPurchaseFromSupport.coupons.purchaseModal.buttons.no")}
                        </CommonButton>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
});
