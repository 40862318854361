import axios from "axios";
import { getBaseUrl } from "common/getBaseUrl";

export function logRefreshJwtLogicError({ context, error, extra }) {
  const url = "dashboards/refresh/logRefreshJwtLogicError";
  const baseUrl = getBaseUrl();
  const urlWithBase = `${baseUrl}${url}`;

  axios({
    url: urlWithBase,
    method: "POST",
    data: { extra, context, errorMessage: error.message, errorCode: error.code },
    withCredentials: true,
  });
}
