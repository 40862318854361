import AuthLayout from "../layouts/Auth";
import { Login as LoginV2 } from "screens/loginV2";
import { UserBalance } from "screens/support/balance";
import { Home } from "screens/support/home";
import { Coupons } from "screens/support/coupons";
import { EditUserInfo } from "screens/support/editUserInfo";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { DailyStepsAndGoals } from "screens/support/dailyStepsAndGoals";
import { OrderDetails } from "screens/support/orderDetails";
import { SendNotificationToUser } from "screens/support/sendNotificationToUser";
import { ConfirmHideCoupons } from "../screens/support/confirmHideCoupons";
import { EntitledUserData } from "screens/support/getEntitledStatus";
import { AuthWrapper } from "layouts/Dashboard/authWrapper";
import { PurchaseFromSupport } from "../screens/support/purchaseFromSupport/purchaseFromSupport";

export const supportRoutes = [
  {
    path: `/${dashboardPaths.SUPPORT}/login`,
    component: AuthLayout,
    routes: [
      {
        path: `/${dashboardPaths.SUPPORT}/login`,
        exact: true,
        component: LoginV2,
      },
    ],
  },
  {
    path: `/${dashboardPaths.SUPPORT}`,
    component: AuthWrapper,
    routes: [
      {
        path: `/${dashboardPaths.SUPPORT}/home`,
        exact: true,
        component: Home,
      },
      {
        path: `/${dashboardPaths.SUPPORT}/getUserCoupons`,
        exact: true,
        component: Coupons,
      },
      {
        path: `/${dashboardPaths.SUPPORT}/purchaseFromSupport`,
        exact: true,
        component: PurchaseFromSupport,
      },
      {
        path: `/${dashboardPaths.SUPPORT}/userBalance`,
        exact: true,
        component: UserBalance,
      },
      {
        path: `/${dashboardPaths.SUPPORT}/editUserInfo`,
        exact: true,
        component: EditUserInfo,
      },
      {
        path: `/${dashboardPaths.SUPPORT}/getEntitledUserData`,
        exact: true,
        component: EntitledUserData,
      },
      {
        path: `/${dashboardPaths.SUPPORT}/dailyStepsAndGoals`,
        exact: true,
        component: DailyStepsAndGoals,
      },
      {
        path: `/${dashboardPaths.SUPPORT}/orderDetails`,
        exact: true,
        component: OrderDetails,
      },
      {
        path: `/${dashboardPaths.SUPPORT}/sendNotificationToUser`,
        exact: true,
        component: SendNotificationToUser,
      },
      {
        path: `/${dashboardPaths.SUPPORT}/getCouponsSubmittedForHide`,
        exact: true,
        component: ConfirmHideCoupons,
      },
    ],
  },
];
