import React from "react";
import { makeObservable, action, observable } from "mobx";
import { login as loginServer } from "./server/login";
import { t } from "common/localization/translate";
import { getDashboardByPathName } from "./logic/getDashboardByPathName";
import { getStorageKeyByDashobardType } from "commonStores/userInfoStore/localStorage/getStorageKeyByDashobardType";
import { createLocalDataObject, setLocalData } from "commonStores/analytics/localStorage/localPersistence";
import { getErrorTextByErrorCode } from "../../organization/login/store/logic/getErrorTextByErrorCode";
import { resetPasswordSendEmail } from "../../organization/login/store/server/resetPassword";
import { dashboardTypes } from "./logic/dashboardTypes";

const ScreenLoginStoreContext = React.createContext({});
export const ScreenLoginStoreProvider = ScreenLoginStoreContext.Provider;
export const useScreenLoginStore = () => React.useContext(ScreenLoginStoreContext);

class ScreenLoginStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.initProperties();
        makeObservable(this, {
            email: observable,
            password: observable,
            errorText: observable,
            appType: observable,
            isResetPasswordNeeded: observable,
            isShowSuccessResetPasswordPopUp: observable,
            allowedSegments: observable,
            disableResetButton: observable,
            setEmail: action.bound,
            setPassword: action.bound,
            setErrorText: action.bound,
            login: action.bound,
            initProperties: action.bound,
            disableResetPassword: action.bound,
        });
    }

    initProperties() {
        this.email = "";
        this.password = "";
        this.errorText = "";
        this.appType = null;
        this.isResetPasswordNeeded = false;
        this.isShowSuccessResetPasswordPopUp = false;
        this.allowedSegments = null;
        this.disableResetButton = false;
    }

    login = async (history, pathname) => {
        this.setErrorText(null);
        const loginInfo = { email: this.email, password: this.password };
        if (!this.email || !this.password) {
            this.setErrorText(t("screenLogin.description"));
            return;
        }
        const currentDashboardType = getDashboardByPathName(pathname);
        const { isSuccess, extra, errorCode } = await loginServer(loginInfo, currentDashboardType);
        if (isSuccess) {
            const { allowedSegments, currentSegment, currentBusiness, isWowJunior } = extra;
            const segmentToShow = currentSegment || allowedSegments ? allowedSegments[0] : null;
            this.rootStore.userInfoStore.initProperties({
                segmentId: segmentToShow,
                businessId: currentBusiness,
                dashboardType: currentDashboardType,
            });
            this.saveLocalData(currentDashboardType, segmentToShow, currentBusiness);
            this.allowedSegments = allowedSegments;
            if (currentDashboardType !== dashboardTypes.SUPPORT) {
                const newPath = `/${currentDashboardType}/home`;
                history.push(newPath);
            } else {
                if (isWowJunior) {
                    history.push(`/support/getUserCoupons`);
                }
            }
        } else {
            if (extra && extra.isResetPasswordNeeded) {
                this.isResetPasswordNeeded = extra.isResetPasswordNeeded;
                this.appType = extra.appType;
                this.email = extra.email;
            }
            const errorText = getErrorTextByErrorCode(errorCode);
            this.setErrorText(errorText);
        }
    };

    resetPassword = async () => {
        this.setErrorText(null);
        if (!this.email) {
            this.setErrorText(t("screenLogin.editPassword.errorText"));
            return;
        }

        this.disableResetPassword();
        const { errorCode } = await resetPasswordSendEmail({ email: this.email });
        if (errorCode) {
            this.setErrorText(getErrorTextByErrorCode(errorCode));
        } else {
            this.isShowSuccessResetPasswordPopUp = true;
        }
    };

    setEmail(email) {
        this.email = email;
    }

    setPassword(password) {
        this.password = password;
    }

    setErrorText(errorText) {
        this.errorText = errorText;
    }

    saveLocalData = async (dashboardType, currentSegment, currentBusiness) => {
        const key = getStorageKeyByDashobardType(dashboardType);
        const data = createLocalDataObject(currentSegment, currentBusiness);
        await setLocalData(key, data);
    };
    disableResetPassword() {
        this.disableResetButton = true;
        setTimeout(() => {
            this.disableResetButton = false;
        }, 10000);
    }
}

export function createScreenLoginStore(rootStore) {
    const store = new ScreenLoginStore(rootStore);
    return store;
}
