export const resources = {
    "business.home.impressionGraph.title": "Total views",
    "business.home.ClicksGraph.title": "Total clicks",
    "business.home.impressionCube.title": "Total views in last 30 days",
    "business.home.clicksCube.title": "Total clicks in last 30 days",
    "business.home.purchasesCube.title": "Coupons purchased in the last 30 days",
    "businessHome.ImpressionChart.dropDownOption.6Months": "In the last six months",
    "businessHome.ImpressionChart.dropDownOption.30days": "In the last 30 days",
    "businessHome.ImpressionChart.dropDownOption.3Months": "In the last 3 months",

    "screenHome.totalNumbersCubesRow.avgImprovement.display": "{{number}}%",
    "screenHome.totalNumbersCubesRow.overview.title": "General review",
    "screenHome.totalNumbersCubesRow.membersCount": "Current number of {{selectedSegmentName}} members",
    "screenHome.totalNumbersCubesRow.thisWeekNewMembersCount": "Number of {{selectedSegmentName}} members that joined this week",
    "screenHome.totalNumbersCubesRow.totalMembersToDemandPayment": "{{selectedSegmentName}} members that met a goal at least once in {{date}}",
    "screenHome.totalNumbersCubesRow.totalMembersToDemandPaymentLeumit": "{{selectedSegmentName}} members that met the payment demand criteria in {{date}}",
    "screenHome.totalNumbersCubesRow.totalCoinsSum": "Total coins of {{selectedSegmentName}} members",
    "screenHome.totalNumbersCubesRow.totalCoinsSum.todaysSum.value": "+{{count}}",
    "screenHome.totalNumbersCubesRow.totalCoinsSum.todaysSum.tooltip": "Coins added yesterday",
    "screenHome.totalNumbersCubesRow.avgImprovement.monthly": "Average improvement of {{selectedSegmentName}} this month",
    "screenHome.totalNumbersCubesRow.avgImprovement.weekly": "Average improvement of {{selectedSegmentName}} this week",
    "screenHome.topReferrals.tableTitle": "Referral",
    "screenHome.topReferrals.tableSubTitle": "Lead by adding friends",
    "screenHome.topReferrals.addedNumber": "added {{count}} friends",
    "screenHome.topReferrals.addedNumber.singular": "added 1 friend",
    "screenHome.bonusesTotals.title": "Distribution of bonuses",
    "screenHome.bonusesTotals.articles": "Articles",
    "screenHome.bonusesTotals.referral": "Referral",
    "screenHome.bonusesTotals.articles.dataText": "{{value}} read",
    "screenHome.bonusesTotals.referral.dataText": "{{value}} joined",
    "screenHome.bonusesTotals.bmi.dataText": "{{value}} entered",
    "screenHome.newUsersChart.title": "Rate of new members joining",
    "screenHome.newUsersChart.labelValueType": "new joiners",
    "screenHome.newUsersChart.dropDownOption.6Months": "In the last six months",
    "screenHome.newUsersChart.dropDownOption.3Months": "In the last 3 months",
    "screenHome.newUsersChart.dropDownOption.30Days": "In the last 30 days",
    "screenHome.newUsersChart.dropDownOption.7Days": "In the last week",
    "screenHome.newUsersChart.dropDownOption.90Days": "In the last 90 days",
    "screenHome.hideCoupon.title": "Hide a bonus",
    "screenHome.openAppCountsChart.title": "App opens",
    "screenHome.openFeaturesCountsChart.title": "Features opens",
    "screenHome.openFeaturesCountsChart.totalEntries": "Total entries this month",
    "screenHome.openFeaturesCountsChart.totalUniqueEntries": "Total users this month",
    "screenHome.openFeaturesCountsChart.articles": "Articles",
    "screenHome.openFeaturesCountsChart.water": "Water",
    "screenHome.openFeaturesCountsChart.videosMain": "Traning videos",
    "screenHome.openFeaturesCountsChart.stepsTranings": "Step count traning",
    "screenHome.openFeaturesCountsChart.videosMain.homeScreen": "Home screen",
    "screenHome.openFeaturesCountsChart.videosMain.traningVideos": "Traning videos",
    "screenHome.openFeaturesCountsChart..videosMain.roniVideos": "Roni Duani's training workshops",
    "screenHome.openFeaturesCountsChart.videosMain.maindfulness": "Maindfulness",
    "screenHome.openAppCountsChart.labelValueType": "Total entries",
    "screenHome.openAppCountsChart.labelValueTypeDistinct": "Total users",
    "screenHome.activeUsers.title": "Active users",
    "screenHome.activeUsersChart.labelValueType": "Active users",
    "screenHome.changeSegment.all": "All",
    "screenHome.excel.appOpen.date": "Date",
    "screenHome.excel.appOpen.entries": "Number of entries",
    "screenHome.excel.appOpen.distinctCount": "Number of users",
    "screenHome.meausermentsTotal.title": "User measurements",
    "screenHome.meausermentsTotal.articles": "read articles",
    "screenHome.meausermentsTotal.water": "reported drinking",
    "screenHome.meausermentsTotal.sleep": "entered sleep hours",
    "screenHome.meausermentsTotal.bmi": "measured BMI",
    "screenHome.meausermentsTotal.nutrition": "entered nutrition",

    "topBar.logout.title": "Log out",

    "sideBar.menu.title": "Menu",
    "sideBar.user.welcome": "Hi {{userName}}",
    "sideBar.home.title": "Home",
    "sideBar.coupons.title": "Rewards",
    "sideBar.support.sendNotificationToUser": "Notifications",
    "sideBar.benefits.title": "Benefits Details",
    "sideBar.subSegmentUsers.title": "User Segmentation by Organizations",
    "sideBar.multiSegmentUsers.title": "User Segmentation",
    "sideBar.userConfirmaions.title": "Permissions and Confirmations",
    "sideBar.userBalance.title": "Balance Tracking",
    "sideBar.support.editUserInfo": "Edit User Information",
    "sideBar.support.orderDetails": "Order Details",
    "sideBar.support.confirmHideCoupons": "Confirm Hiding Coupons",
    "sideBar.appOpenByHours.title": "App Open by Hours",
    "sideBar.support.dailyStepsAndGoals": "Daily Steps and Goals",
    "sideBar.traines.title": "Participants",
    "sideBar.staff.title": "Staff",
    "sideBar.appOpenByHours.hideCoupon": "Hide Coupon",
    "sideBar.healthAndFitnessData.title": "Health and Fitness Data",

    "login.title": "Log in",
    "login.enterDetails": "Enter your email and password to log in",
    "login.email": "Email",
    "login.password": "Password",
    "login.button": "Log in",
    "login.segmentCard.chooseSegment.label": "Select an organization",
    "login.segmentCard.chooseSegment.button": "Enter",
    "login.segmentCard.chooseSegment.dropDownLabel": "organizations list",
    "login.mailSuccessResetPassword.title": "Reset password",
    "login.mailSuccessResetPassword.text": "We sent you a link to reset your password to your email inbox",
    "login.mailSuccessResetPassword.button": "Great, thanks",

    "screenLogin.errorText": "This user has no authorization. Check your email and password.",
    "screenLogin.errorText.resetPassword": "You must reset your password to log in",
    "screenLogin.errorText.maintenence": "...oops, we popped out, be right back",
    "screenLogin.errorText.limitExceeded": "You have reached the maximum number of login attempts. Please try again later",
    "screenLogin.errorText.resetPasswordLimitExceeded": "You have reached the maximum number of password reset attempts. Please try again later",
    "screenLogin.editPassword.errorText": "Please enter your email",
    "screenLogin.chooseSegment.errorText": "Select an organization to enter",

    "screenPurchases.table.title": "Purchases",
    "screenPurchases.table.col.couponTitle": "Coupon name",
    "screenPurchases.table.col.businessName": "Business name",
    "screenPurchases.table.col.couponCount": "Total purchases",
    "screenPurchases.table.col.segmentName": "Name of organization",

    "screenPurchaseUsersInfo.table.title": "Purchase details",
    "screenPurchaseUsersInfo.table.col.couponTitle": "Coupon name",
    "screenPurchaseUsersInfo.table.col.couponCodeTitle": "Reward code/order number",
    "screenPurchaseUsersInfo.table.col.identifier": "ID No.",
    "screenPurchaseUsersInfo.table.col.phone": "Telephone",
    "screenPurchaseUsersInfo.table.col.userName": "User name",
    "screenPurchaseUsersInfo.table.col.date": "Date",
    "screenPurchaseUsersInfo.table.col.segmentName": "Organization name",
    "screenPurchaseUsersInfo.table.col.pickUpPoint": "Collection point",
    "screenPurchaseUsersInfo.table.col.isUsed": "Redeemed",
    "screenPurchaseUsersInfo.table.col.costToOvdim": "Cost in Shekels (including VAT)",
    "screenPurchaseUsersInfo.table.col.coins": "Cost in Health Coins",
    "screenPurchaseUsersInfo.table.col.isUsedTrue": "Yes",
    "screenPurchaseUsersInfo.table.col.isUsedFalse": "No",
    "screenPurchaseUsersInfo.table.noData": "-",

    "screenCouponsInInventory.table.title": "Benefits in stock",
    "screenCouponsInInventory.table.col.couponTitle": "Coupon name",
    "screenCouponsInInventory.table.col.businessName": "Business name",
    "screenCouponsInInventory.table.col.couponCount": "Total in stock",
    "screenCouponsInInventory.button.allInInventory": "Currently in stock",
    "screenCouponsInInventory.downloadExcel": "Export to Excel",

    "organizationDashboard.purchases.tooltip": "This screen includes all the benefits purchased by the project members by name, type of business or benefit",
    "organizationDashboard.purchasesUsersInfo.tooltip1": "This screen includes a list of dedicated benefits only purchased by members of the organization, by user",
    "organizationDashboard.purchasesUsersInfo.tooltip2": "That is, benefits purchased from Rumble's eco system will not appear on this screen",
    "organizationDashboard.couponsInInventory.tooltip1": "This screen includes a list of benefits and the amount in stock for each of them",
    "organizationDashboard.couponsInInventory.tooltip2": "You can select any period between two dates in order to see which benefits are currently in stock, were added in the selected period.",

    "organizationDashboard.confirmations.tooltip1": "On this screen, you can search for any member of the project by using the ID No.",
    "organizationDashboard.confirmations.tooltip2": "The screen shows for each member the date of approval of the regulations and a list of changes to the mailing approval.",
    "organizationDashboard.subSegmentUsers.tooltip": "This screen shows the list of the names of the organizations as they appear in the system and the number of their employees registered for the program.",
    "organizationDashboard.appOpenByHours.tooltip": "This screen shows the application opening data divided by hours and date.",
    "organizationDashboard.hideCoupon.tooltip": "On this screen you can hide a benefit from the list of benefits displayed in the application.",
    "organizationDashboard.hideCouponType.selectCouponType": "Choose a benefit",
    "organizationDashboard.hideCouponType.hideCouponTypeButton": "Hide the benefit",
    "organizationDashboard.hideCouponType.AreYouSurePopUpTitle": "Hiding a coupon",
    "organizationDashboard.hideCouponType.AreYouSurePopUpContent": "Are you sure you want to hide the {{couponTitle}} benefit?",
    "organizationDashboard.hideCouponType.AreYouSurePopUpContent2": "For your information, you will not be able to reveal the benefit after hiding it.",
    "organizationDashboard.hideCouponType.successHideCoupn": "The {{couponTypeTitle}} reward has been successfully hidden.",
    "organizationDashboard.hideCouponType.successHideCouponNote": "You have to wait up to a minute to see the change in the application itself.",

    "sideBar.subSegmentUsers.title": "Division of users into organizations",
    "sideBar.userConfirmaions.title": "Permissions & approvals",
    "sideBar.multiSegmentUsers.title": "users division",
    "sideBar.appOpenByHours.title": "Opening hours",
    "sideBar.healthAndFitnessData.title": "Health and fitness data",
    "sideBar.traines.title": "participants",
    "sideBar.staff.title": "staff",
    "sideBar.appOpenByHours.hideCoupon": "Hiding a coupon",

    "screenSubSegmentUsers.table.title": "Division of users into organizations",
    "screenSubSegmentUsers.table.col.subSegmentName": "Organization name",
    "screenSubSegmentUsers.table.col.count": "Amount of users",

    "screenConfirmationUser.title": "User Permissions",
    "screenConfirmationUser.subtitle": "To receive details, please enter ID No.",
    "screenConfirmationUser.id": "ID No.",
    "screenConfirmationUser.button": "sending",
    "screenConfirmationUser.errors.onlyNumbers": "ID No. can only contain digits",
    "screenConfirmationUser.errors.idNumberTooShort": "ID No. must contain at least 6 digits",
    "screenConfirmationUser.errors.userNotExsist": "We did not find a user with such an ID No.",
    "screenConfirmationUser.errors.invalidInput": "The ID entered is incorrect",
    "screenConfirmationUser.MaillingConfirmations.startDate": "Starting from",

    "screenHealthAndFitnessData.data.start": "start data",
    "screenHealthAndFitnessData.data.title": "health data",
    "screenHealthAndFitnessData.data.middle": "middle data",
    "screenHealthAndFitnessData.data.finish": "finish data",

    "excel.sheet.summaries": "Summaries",
    "excel.sheet.openingRate": "App Opening Rate",
    "excel.sheet.bonuses": "Distribution of bonuses",
    "excel.sheet.joiningMembersRate": "Rate of joining new members",
    "excel.sheet.referral": "Referral",
    "excel.sheet.averageImprovement": "Average {{selectedSegmentName}} improvement this month",
    "excel.sheet.membersNumber": "The number of {{selectedSegmentName}} members now",
    "excel.sheet.membersNumber.lastWeek": "The number of {{selectedSegmentName}} members who joined in the last week",
    "excel.sheet.coinsTotal": "All members' coins of {{selectedSegmentName}}",
    "excel.sheet.weeklyImprovement": "Weekly Improvement {{selectedSegmentName}}",
    "excel.sheet.type": "type",
    "excel.sheet.amount": "amount",
    "excel.sheet.date": "date",
    "excel.sheet.user": "user",
    "excel.sheet.addedFriends": "Added Friends",
    "excel.sheet.activeUsers": "Active users growth rate",

    "screenSendNotificationToUser.title": "Notifications",
    "support.sendNotificationScreen.subtitle": "Enter an ID to target a specific user or select all users.",
    "support.common.placeHolder": "ID",
    "support.sendNotificationScreen.userNotExsist": "We did not find a user with that ID number",
    "support.sendNotificationScreen.invalidData": "The entered ID isn't valid",
    "support.sendNotificationScreen:chooseAllUsersButton": "Select all users",
    "support.sendNotificationScreen.userName": "Username: {{userName}}",
    "support.sendNotificationScreen.idNum": "ID: {{idNum}}",
    "support.sendNotificationScreen.notificationNavigation": "The push notification should link to:",
    "sendNotificationScreen.navigationList.homeScreen": "Home screen",
    "sendNotificationScreen.navigationList.competition": "Competition",
    "sendNotificationScreen.navigationList.mindfulness": "Mindfulness",
    "sendNotificationScreen.navigationList.history": "History",
    "sendNotificationScreen.navigationList.myGroups": "My groups",
    "sendNotificationScreen.navigationList.virtualTrainer": "Virtual trainer",
    "sendNotificationScreen.navigationList.articles": "Learn and earn- articles",
    "support.sendNotificationScreen.notificationType": "Notification type",
    "support.sendNotificationScreen.emptyType": "You must choose notification type",
    "support.sendNotificationScreen.notificationType:updates": "Updates",
    "support.sendNotificationScreen.notificationType:push": "Push",
    "general.addText": "Insert text",
    "general.text": "Text:",
    "support.sendNotificationScreen.emptyText": "You must enter text",
    "support.sendNotificationScreen.selfSendButton": "Test",
    "support.sendNotificationScreen.sendToUser": "Send",
    "support.sendNotificationScreen.toolTipContent": "You must send the notification to yourself first and only then to the user",
    "support.sendNotificationScreen.toolTipContent:allUsers": "You must send the notification to yourself first and only then to the users",
    "support.sendNotificationScreen.successSendNotificationTitlie": "Notifications",
    "support.sendNotificationScreen.successSendNotificationContent": "Notification sent successfully!",
    "support.sendNotificationScreen.successSendNotificationButton": "Great",
    "support.sendNotificationScreen.sendToAllUser": "Send To All",
    "support.sendNotificationScreen.sendNotificationPopUpErrorMessageButton": "Close",
    "support.sendNotificationScreen.sendNotificationPopUpErrorMessage:noCompetition": "There is no live competition at the moment. Please choose a different option.",
    "support.sendNotificationScreen.sendNotificationPopUpErrorMessage:limit": "Limit Exceeded",
};
