import { t } from "common/localization/translate";

export const errorCodes = {
    USER_NOT_FOUND: 1,
    NO_PERMISSION: 2,
    NO_BUSINESSES: 3,
    NOT_ALLOWED_SEGMENT: 4,
    PASSWORD_RESET_REQUIRED: 5,
    MAINTENANCE_MODE: 6,
    LIMIT_EXCEEDED: 7,
    RESET_PASSWORD_LIMIT_EXEEDED: 8,
};

export function getErrorTextByErrorCode(errorCode) {
    if (errorCode === errorCodes.PASSWORD_RESET_REQUIRED) {
        return t("screenLogin.errorText.resetPassword");
    }
    if (errorCode === errorCodes.MAINTENANCE_MODE) {
        return t("screenLogin.errorText.maintenence");
    }
    if (errorCode === errorCodes.LIMIT_EXCEEDED) {
        return t("screenLogin.errorText.limitExceeded");
    }
    if (errorCode === errorCodes.RESET_PASSWORD_LIMIT_EXEEDED) {
        return t("screenLogin.errorText.resetPasswordLimitExceeded");
    }
    return t("screenLogin.errorText");
}
