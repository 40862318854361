import axios from "axios";
import { getBaseUrl } from "common/getBaseUrl";
import { getStatusAfterDelay } from "./getStatusAfterDelay";
import { logRefreshJwtLogicError } from "./logRefreshJwtLogicError";

export async function refreshJwtToken({ originalRequest, tokenRefreshStatus }) {
  const url = "dashboards/refresh";
  const baseUrl = getBaseUrl();
  const urlWithBase = `${baseUrl}${url}`;

  try {
    if (tokenRefreshStatus.isRefreshing) {
      const status = await getStatusAfterDelay(tokenRefreshStatus);
      return status;
    }

    tokenRefreshStatus.isRefreshing = true;
    tokenRefreshStatus.isSuccessfullyRefreshed = false;
    const res = await axios({
      url: urlWithBase,
      method: "POST",
      data: { originalRequestUrl: originalRequest.url },
      withCredentials: true,
    });

    return res.data;
  } catch (e) {
    logRefreshJwtLogicError({ context: "Error in refreshJwtToken", error: e });
    return { isSuccess: false };
  }
}
