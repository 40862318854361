import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useScreenLoginStore } from "../store";
import { t } from "common/localization/translate";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const useStyles = makeStyles(theme => ({
    submitButton: {
        marginTop: theme.spacing(2),
        textDecorationLine: "underline",
        "&:hover": {
            cursor: "pointer",
            fontWeight: "bold",
        },
    },
    disable: {
        textDecoration: "none",
        opacity: 0.5,
        "&:hover": {
            cursor: "default",
            fontWeight: "normal",
        },
    },
}));

export const ResetPassword = observer(() => {
    const store = useScreenLoginStore();
    const classes = useStyles();
    const { disableResetButton } = store;

    const resetPasswordSendEmail = () => {
        if (disableResetButton) {
            return;
        }
        store.resetPassword({ isFromOrganization: true });
    };

    return (
        <Grid className={[classes.submitButton, disableResetButton ? classes.disable : null]} onClick={resetPasswordSendEmail} type="submit">
            <Typography variant="subtitle2">{t("login.mailSuccessResetPassword.title")}</Typography>
        </Grid>
    );
});
