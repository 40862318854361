import { t } from "../../../../../common/localization/translate";
import { activationTypes } from "./coupontypeActivationTypes";
import { formatMinutesToDisplay } from "./formatMinutesToDisplay";

export function getCouponsTableData(store) {
    const { allCoupons } = store;
    const tableDataArray = allCoupons
        ? allCoupons.map(item => [
              item.couponName, //name
              item, // images
              `${item.coins} ${item.priceInCurrency ? "+" + item.priceInCurrency + " ILS" : ""}`, //coins
              item.couponDescription, //description
              getActivationType({ activationType: item.activationType, businessLocation: item.businessLocation }), //activation type
              formatMinutesToDisplay(item.activeDurationMin), //duration
              item, //purchase button
          ])
        : [];
    return tableDataArray;
}

const getActivationType = ({ activationType, businessLocation }) => {
    switch (activationType) {
        case activationTypes.CODE:
            return t("screenPurchaseFromSupport.coupons.column.couponType.code", { businessLocation });
        case activationTypes.LINK:
        case activationTypes.CODE_AND_LINK:
            return t("screenPurchaseFromSupport.coupons.column.couponType.online");
        case activationTypes.DELIVERY:
            return t("screenPurchaseFromSupport.coupons.column.couponType.delivery");
        case activationTypes.PICKUP:
        case activationTypes.COLLECTING_POINT:
            return t("screenPurchaseFromSupport.coupons.column.couponType.pickup");
        default:
            return t("screenPurchaseFromSupport.coupons.column.couponType.online");
    }
};
